import React from 'react'
import Layout from '../components/Layout'
import RegisterForm from '../components/RegisterForm'
import styles from './Register.module.scss'

function Register() {
  return (
    <Layout title="Регистрация">
      <section className={'app-content ' + styles.page}>
        <div className="container">
          <RegisterForm />
        </div>
      </section>
    </Layout>
  )
}

export default Register

import React from 'react'
import styles from './Anons.module.scss'

function Anons() {
  return (
    <div className={styles.anons} id="lectures">
      <div className="app-content">
        <div className="container">
          <h1>Анонс</h1>
          <div className={styles.date}>16 мая 2024 года</div>
          <div className={styles.lectures}>
            <div className={styles.item}>
              <div className={styles.meta}>
                <div className={styles.number}>01</div>
              </div>
              <div className={styles.content}>
                <div className={styles.author}>
                  <div className={styles.name}>Станислав Ракчаев</div>
                  <div className={styles.position}></div>
                </div>
                <div className={styles.description}>
                  <p>Проектирование, сервисы, проектирование и создание сервисов</p>
                  Рассмотрим сервисы, что это такое, какие бывают, зачем нужны. Так же, немного
                  расскажу про проектирование и какое оно бывает. Ну и, кончено, создадим
                  какой-нибудь сервис.
                </div>
              </div>
            </div>

            <div className={styles.item}>
              <div className={styles.meta}>
                <div className={styles.number}>02</div>
              </div>
              <div className={styles.content}>
                <div className={styles.author}>
                  <div className={styles.name}>Сергей Кирьяков</div>
                  <div className={styles.position}></div>
                </div>
                <div className={styles.description}>
                  <p>Почему не страшно ошибаться?</p>
                  История развития команды, технологические и продуктовые вызовы, факапы. Или как
                  совместить технологии и бизнес, не построив новый монолит.
                </div>
              </div>
            </div>

            <div className={styles.item}>
              <div className={styles.meta}>
                <div className={styles.number}>03</div>
              </div>
              <div className={styles.content}>
                <div className={styles.author}>
                  <div className={styles.name}>Мария Осипенко</div>
                  <div className={styles.position}></div>
                </div>
                <div className={styles.description}>
                  <p>Логирование</p>
                  Рассуждения о методах, причинах, инструментах, стратегиях логирования для веб
                  приложений разных масштабов.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Anons

import React from 'react'
import styles from './Footer.module.scss'

export default function Footer() {
  const year = new Date().getFullYear()

  return (
    <footer className={styles.footer}>
      <div className="container">
        <div className={styles.wrapper}>
          <div className={styles.copyright}>&copy; {year} LIVEPHP</div>
        </div>
      </div>
    </footer>
  )
}

import React from 'react'
import Layout from '../components/Layout'
import Welcome from '../components/Welcome'
import Anons from '../components/Anons'

function Home() {
  return (
    <Layout
      title="LivePHP"
      meta={[
        {
          name: 'description',
          content: 'Митапы по программированию',
        },
      ]}
    >
      <Welcome />
      <Anons />
    </Layout>
  )
}

export default Home

import React from 'react'
import styles from './Header.module.scss'
import { Link } from 'react-router-dom'

export default function Header() {
  // const location = useLocation()

  return (
    <header className={styles.header}>
      <div className="container">
        <div className={styles.wrapper}>
          <div className={styles.logo}>
            <Link to="/">LivePHP</Link>
          </div>
          <nav className={styles.nav}>
            {/* <ul>
              <li className={location.pathname === '/' ? styles.active : ''}>
                <Link href="/">Анонс</Link>
              </li>
              <li
                className={location.pathname === '/register' ? styles.active : ''}
              >
                <Link href="/register">Регистрация</Link>
              </li>
            </ul> */}
          </nav>
        </div>
      </div>
    </header>
  )
}

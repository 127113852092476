import React from 'react'
import { Link } from 'react-router-dom'
import Layout from '../components/Layout'

function NotFound() {
  return (
    <Layout title="Не найдено">
      <h1>Error</h1>
      <p>Page is not found</p>
      <p>
        <Link to="/">Back to Home</Link>
      </p>
    </Layout>
  )
}

export default NotFound

import React, { ReactNode } from 'react'
import Header from './Header'
import Footer from './Footer'
import { useLocation } from 'react-router-dom'
import { Helmet } from 'react-helmet'

type Meta = {
  name: string
  content: string
}

type Script = {
  src: string
}

type Props = {
  title: string
  meta?: Meta[]
  scripts?: Script[]
  children: ReactNode
}

const Layout = ({ children, title, meta = [], scripts = [] }: Props) => {
  const location = useLocation()

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <link rel="icon" href="/favicon.ico" />
        <link rel="canonical" href={'https://livephp.ru' + location.pathname.split('?')[0]} />
        {meta ? meta.map((item, index) => <meta {...item} key={'meta-' + index} />) : null}
        {scripts
          ? scripts.map((item, index) => <script {...item} key={'script-' + index} />)
          : null}
      </Helmet>

      <div className="app">
        <main>
          <Header />
          {children}
        </main>
        <Footer />
      </div>
    </>
  )
}

export default Layout

import React, { useState } from 'react'
import styles from './RegisterForm.module.scss'
import handleResponse from '../handleResponse'
import AlertSuccess from './Alert/AlertSuccess'
import AlertError from './Alert/AlertError'

function RegisterForm() {
  const [formData, setFormData] = useState({
    email: '',
    firstName: '',
    lastName: '',
    telegram: '',
    proposal: '',
    message: '',
    agree: false,
  })
  const [buttonEnable, setButtonEnable] = useState(true)
  const [errors, setErrors] = useState<Record<string, string>>({})
  const [error, setError] = useState<string | null>(null)
  const [success, setSuccess] = useState<string | null>(null)

  const handleChangeInput = (event: React.FormEvent<HTMLInputElement>) => {
    const input = event.currentTarget
    setFormData({
      ...formData,
      [input.name]: input.type === 'checkbox' ? input.checked : input.value,
    })
  }

  const handleChangeText = (event: React.FormEvent<HTMLTextAreaElement>) => {
    const input = event.currentTarget
    setFormData({
      ...formData,
      [input.name]: input.value,
    })
  }

  function handleSubmit(event: React.SyntheticEvent) {
    event.preventDefault()

    setButtonEnable(false)
    setError(null)
    setErrors({})

    if (!formData.agree) {
      setErrors({ agree: 'Согласитесь с условиями' })
      return
    }

    fetch('/api/v1/register', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
    })
      .then(handleResponse)
      .then(() => {
        setSuccess('Спасибо за регистрацию!')
        setButtonEnable(true)
      })
      .catch(async (error) => {
        if (error.status === 409) {
          const data = await error.json()
          setButtonEnable(true)
          setError(data.error)
          return
        }
        if (error.status === 422) {
          const data = await error.json()
          setButtonEnable(true)
          setErrors(data.errors)
          return
        }
        setButtonEnable(true)
        setError(JSON.stringify(error))
      })
  }

  return (
    <>
      <AlertError message={error} />
      <AlertSuccess message={success} />

      {!success ? (
        <>
          <div className={styles.form} data-testid="register-form">
            <h1>Регистрация</h1>
            <div className={styles.offer}>
              <p>Зарегистрируйся на наше мероприятие:</p>
            </div>
            <form method="POST" className="form" onSubmit={handleSubmit}>
              <div className={styles.half}>
                <div className="input-row">
                  <label htmlFor="firstName" className="input-label">
                    Имя*
                  </label>
                  <input
                    id="firstName"
                    name="firstName"
                    type="text"
                    value={formData.firstName}
                    onChange={handleChangeInput}
                    className="input"
                    placeholder="Введи своё имя..."
                    required
                  />
                  {errors.firstName ? <div className="error">{errors.firstName}</div> : null}
                </div>
                <div className="input-row">
                  <label htmlFor="lastName" className="input-label">
                    Фамилия
                  </label>
                  <input
                    id="lastName"
                    name="lastName"
                    type="text"
                    value={formData.lastName}
                    onChange={handleChangeInput}
                    className="input"
                    placeholder="Введи свою фамилию..."
                  />
                  {errors.lastName ? <div className="error">{errors.lastName}</div> : null}
                </div>
              </div>
              <div className="input-row">
                <label htmlFor="email" className="input-label">
                  Email*
                </label>
                <input
                  id="email"
                  name="email"
                  type="email"
                  value={formData.email}
                  onChange={handleChangeInput}
                  className="input"
                  placeholder="Введи свой email..."
                  required
                />
                {errors.email ? <div className="error">{errors.email}</div> : null}
              </div>
              <div className="input-row">
                <label htmlFor="telegram" className="input-label">
                  Имя в телеграм
                </label>
                <input
                  id="telegram"
                  name="telegram"
                  type="text"
                  value={formData.telegram}
                  onChange={handleChangeInput}
                  className="input"
                  placeholder="Введи своё имя телеграм..."
                />
                {errors.telegram ? <div className="error">{errors.telegram}</div> : null}
              </div>
              <div className="input-row">
                <label htmlFor="proposal" className="input-label">
                  Какие темы докладов на будущее хотел услышать?
                </label>
                <input
                  id="proposal"
                  name="proposal"
                  type="text"
                  value={formData.proposal}
                  onChange={handleChangeInput}
                  className="input"
                  placeholder="Введи темы докладов..."
                />
                {errors.proposal ? <div className="error">{errors.proposal}</div> : null}
              </div>
              <div className="input-row">
                <label htmlFor="message" className="input-label">
                  Напиши, что еще хотелось бы добавить
                </label>
                <textarea
                  id="message"
                  name="message"
                  value={formData.message}
                  onChange={handleChangeText}
                  className="input"
                  rows={4}
                  placeholder="Введи свое сообщение..."
                />
                {errors.message ? <div className="error">{errors.message}</div> : null}
              </div>
              <div className={styles.half}>
                <div className="input-row">
                  <label className={styles.agree}>
                    <input
                      name="agree"
                      type="checkbox"
                      checked={formData.agree}
                      onChange={handleChangeInput}
                      required
                    />
                    <span>
                      Поставив галочку в этом поле я даю согласие на обработку своих данных
                    </span>
                  </label>
                  {errors.agree ? <div className="error">{errors.agree}</div> : null}
                </div>
                <div className={'button-row ' + styles.buttons}>
                  <button type="submit" className="submit" disabled={!buttonEnable}>
                    Отправить
                  </button>
                </div>
              </div>
            </form>
          </div>
        </>
      ) : null}
    </>
  )
}

export default RegisterForm
